<template>

  <main class="contact">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Radio Kraków Kultura" class="breadcrumb">
            Radio Kraków Kultura
          </router-link>


          <router-link to="/contact" title="Kontakt" class="breadcrumb">
            Kontakt
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Radio Kraków S.A.</h1>
        </div>
      </div>
      <div class="row mb-0">

        <div class="col s12 m6 l6">
          <div class="contact-block">
            <b>Recepcja:</b>
            <span></span> <br>
            <b>12 630 60 00  </b>
            <span> </span> <br>
          </div>
        </div>
        <div class="col s12 m6 l6">
          <div class="contact-block">

            <b class="width-auto">Redakcja Informacji</b> <br>
            <a href="mailto:rkk@radiokrakow.pl">rkk@radiokrakow.pl</a>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col s12">


          <iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Kraków Al. Słowackiego 22&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  class="radius"></iframe>
        </div>
      </div>
      <div class="row">

        <div class="col s12">
          <div class="row mb-5">

            <div class="col s12">
              <div class="contact-block">

                <b class="width-auto">Redaktor Naczelny - Likwidator Spółki</b> <br>
                <h4 class="width-auto">Mariusz Marcin Pulit</h4>
                <a href="mailto:sekretariat.glowny@radiokrakow.pl">sekretariat.glowny@radiokrakow.pl</a>
                <br>
              </div>
            </div>
            <div class="col s12">

              <div class="row mb-2">
                <div class="col s12 m6">
                  <div class="contact-block">
                    <b class="width-auto">Redaktorka programowa</b> <br>
                    <b>Georgina Gryboś</b> <a href="mailto:grybos@radiokrakow.pl">grybos@radiokrakow.pl</a> <br>
                  </div>
                </div>
                <div class="col s12 m6">
                  <div class="contact-block">
                    <b class="width-auto">Kierownik muzyczny</b> <br>
                    <b>Wojciech Barczyński</b> <a href="mailto:barczynski@radiokrakow.pl">barczynski@radiokrakow.pl</a>
                  </div>
                </div>
              </div>
              <div class="row mb-0">
                <div class="col s12 m6">
                  <div class="contact-block">
                    <b class="width-auto">Sekretarz redakcji</b> <br>
                    <b>Szymon Smółka</b> <a href="mailto:smolka@radiokrakow.pl">smolka@radiokrakow.pl</a> <br>
                  </div>
                </div>
                <div class="col s12 m6">
                  <div class="contact-block">
                    <b class="width-auto">Kurator muzyczny</b> <br>
                    <b>Krzysztof Pietraszewski</b>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col s12">

              <h1 class="big-title">Logoset</h1>
              <a a href="./app/data/Logo - RadioKrakówKultura.zip" class="logo-download" tag="a">
                <img src="@/assets/logo.png" alt="Radio Kraków Kultura">
              </a>
              <br>
              <a href="./app/data/Logo - RadioKrakówKultura.zip">
              Pobierz
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </main>
  <SectionInfo code="contact"/>
</template>

<script>
import SectionInfo from "./Home/SectionInfo";

export default {
  name: "Contact",
  components: {SectionInfo}
}
</script>

<style scoped>


.contact-block {
  border-radius: 6px;
  padding: 24px;

  width: 100%;

  background-color: #f0f0f0;

  line-height: 2em;
  margin-bottom: 24px;
}

.contact-block b:not(.width-auto) {
  width: 180px;
  display: inline-block;
}

.contact-block span {
}

.contact-block h4 {
  font-weight: 900;
  font-size: 24px;
  margin: 0 0 12px;
  padding: 0;
}

.logo-download{

  display: inline-block;
  padding: 30px;
  border: 1px solid black;
  margin-top: 15px;
}

.logo-download img{
  width: 200px;
}

</style>
